import React, { useEffect, useState } from 'react';
import './ImageGrid.css';
import ImageCard from './ImageCard';

function ImageGrid({ images, onImageClick }) {
  const [loadedImages, setLoadedImages] = useState([]);

  const preloadImages = (images) => {
    if (!Array.isArray(images)) return Promise.resolve([]);
    return Promise.all(images.map((image) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = image.src;
        img.onload = resolve;
        img.onerror = resolve;
      });
    }));
  };

  useEffect(() => {
    preloadImages(images).then(() => {
      setLoadedImages(images);
    });
  }, [images]);

  if (loadedImages.length === 0) {
    return <div className="loading-spinner"></div>;
  }

  return (
    <div className="image-grid">
      {loadedImages.map((image, idx) => (
        <ImageCard key={idx} image={image} onClick={() => onImageClick(image)} />
      ))}
    </div>
  );
}

export default ImageGrid;