import React, { useState } from 'react';
import './ImagePopup.css';

function ImagePopup({ image, onClose }) {
  const [closing, setClosing] = useState(false);

  const handleClose = () => {
    setClosing(true);
    setTimeout(onClose, 290); // Match the duration of the closing animation
  };

  return (
    <div className={`image-popup ${closing ? 'closing' : ''}`}>
      <span className="closeDiv" onClick={handleClose}> 
        <span className="close">&times;</span>
      </span>
      <div className="popup-content">
        <div className="popup-image-container">
          <img src={image.src} alt={image.title} className="popup-image" />
        </div>
        <div className="popup-details">
          <p><em>{image.title}</em> {image.type} {image.width}" x {image.height}"</p>
        </div>
      </div>
    </div>
  );
}

export default ImagePopup;