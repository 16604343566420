import React from 'react';
import './ImageCard.css';

function ImageCard({ image, onClick }) {
  return (
    <div className="image-card" onClick={onClick}>
      <div className="image-container">
        <img src={image.src} alt={image.title} />
      </div>
    </div>
  );
}

export default ImageCard;