import React from 'react';
import './Home.css';

function Home({ image }) {
  return (
    <div className="home-container">
      <img src={image.src} alt={image.title} className="home-image" />
      <div className="home-details">
        <p><em>{image.title}</em> {image.type} {image.width}" X {image.height}"</p>
      </div>
    </div>
  );
}

export default Home;