import React from 'react';
import './About.css';

function About() {
  return (
    <div className="about-container">
      <h1>About Me</h1>
      <p>
        I was born in Osaka, Japan in 1973. I studied at the Academy of Art University in San Francisco, CA, graduating in 1997 with a BFA. I have lived most of my life in Japan in the Tokyo and Osaka regions, but have also lived in the UK and am currently living in Barrington, IL with my wife, Mary, and stepchildren.
      </p>
      <p>
        When I was young, I had exhibits in Tokyo at the Nakaochiai Gallery in Tokyo in 2005 and 2008. I am just now returning to exhibits starting with Art in the Barn in Barrington, IL 2024. My current specialty is landscape painting with oil.
      </p>
    </div>
  );
}

export default About;