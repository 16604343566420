import React from 'react';
import './Contact.css';

function Contact() {
  return (
    <div className="contact-container">
      <h1>Contact</h1>
      <p>For any inquiries regarding the purchase of a painting displayed on this site, please contact: <a href="mailto:spcd4969@gmail.com">spcd4969@gmail.com</a></p>
    </div>
  );
}

export default Contact;