import React from 'react';
import './CV.css';

function CV() {
  return (
    <div className="cv-container">
      <h1>Seiji Yonehara</h1>
      <p>b. 1973 Osaka, Japan</p>

      <h2>Selected One and Two Person Exhibitions</h2>
      <ul>
        <li>
          <strong>2024</strong> Art in the Barn, Barrington, IL
        </li>
      </ul>
      <h2>Awards</h2>
      <ul>
        <li>
          <strong>2024</strong> Best Of Oil <strong>at</strong> Art in the Barn, Barrington, IL
        </li>
      </ul>
    </div>
  );
}

export default CV;